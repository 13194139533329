/* eslint-disable import/prefer-default-export */
export const TEAMS = {
  1610612737: ['ATL', 'Hawks', 1949, 'Atlanta', 'Atlanta Hawks', 'Atlanta', [1958]],
  1610612738: ['BOS', 'Celtics', 1946, 'Boston', 'Boston Celtics', 'Massachusetts', [1957, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1968, 1969, 1974, 1976, 1981, 1984, 1986, 2008]],
  1610612739: ['CLE', 'Cavaliers', 1970, 'Cleveland', 'Cleveland Cavaliers', 'Ohio', [2016]],
  1610612740: ['NOP', 'Pelicans', 2002, 'New Orleans', 'New Orleans Pelicans', 'Louisiana', []],
  1610612741: ['CHI', 'Bulls', 1966, 'Chicago', 'Chicago Bulls', 'Illinois', [1991, 1992, 1993, 1996, 1997, 1998]],
  1610612742: ['DAL', 'Mavericks', 1980, 'Dallas', 'Dallas Mavericks', 'Texas', [2011]],
  1610612743: ['DEN', 'Nuggets', 1976, 'Denver', 'Denver Nuggets', 'Colorado', []],
  1610612744: ['GSW', 'Warriors', 1946, 'Golden State', 'Golden State Warriors', 'California', [1947, 1956, 1975, 2015, 2017, 2018, 2022]],
  1610612745: ['HOU', 'Rockets', 1967, 'Houston', 'Houston Rockets', 'Texas', [1994, 1995]],
  1610612746: ['LAC', 'Clippers', 1970, 'Los Angeles', 'Los Angeles Clippers', 'California', []],
  1610612747: ['LAL', 'Lakers', 1948, 'Los Angeles', 'Los Angeles Lakers', 'California', [1949, 1950, 1952, 1953, 1954, 1972, 1980, 1982, 1985, 1987, 1988, 2000, 2001, 2002, 2009, 2010, 2020]],
  1610612748: ['MIA', 'Heat', 1988, 'Miami', 'Miami Heat', 'Florida', [2006, 2012, 2013]],
  1610612749: ['MIL', 'Bucks', 1968, 'Milwaukee', 'Milwaukee Bucks', 'Wisconsin', [1971, 2021]],
  1610612750: ['MIN', 'Timberwolves', 1989, 'Minnesota', 'Minnesota Timberwolves', 'Minnesota', []],
  1610612751: ['BKN', 'Nets', 1976, 'Brooklyn', 'Brooklyn Nets', 'New York', []],
  1610612752: ['NYK', 'Knicks', 1946, 'New York', 'New York Knicks', 'New York', [1970, 1973]],
  1610612753: ['ORL', 'Magic', 1989, 'Orlando', 'Orlando Magic', 'Florida', []],
  1610612754: ['IND', 'Pacers', 1976, 'Indiana', 'Indiana Pacers', 'Indiana', []],
  1610612755: ['PHI', '76ers', 1949, 'Philadelphia', 'Philadelphia 76ers', 'Pennsylvania', [1955, 1967, 1983]],
  1610612756: ['PHX', 'Suns', 1968, 'Phoenix', 'Phoenix Suns', 'Arizona', []],
  1610612757: ['POR', 'Trail Blazers', 1970, 'Portland', 'Portland Trail Blazers', 'Oregon', [1977]],
  1610612758: ['SAC', 'Kings', 1948, 'Sacramento', 'Sacramento Kings', 'California', [1951]],
  1610612759: ['SAS', 'Spurs', 1976, 'San Antonio', 'San Antonio Spurs', 'Texas', [1999, 2003, 2005, 2007, 2014]],
  1610612760: ['OKC', 'Thunder', 1967, 'Oklahoma City', 'Oklahoma City Thunder', 'Oklahoma', [1979]],
  1610612761: ['TOR', 'Raptors', 1995, 'Toronto', 'Toronto Raptors', 'Ontario', [2019]],
  1610612762: ['UTA', 'Jazz', 1974, 'Utah', 'Utah Jazz', 'Utah', []],
  1610612763: ['MEM', 'Grizzlies', 1995, 'Memphis', 'Memphis Grizzlies', 'Tennessee', []],
  1610612764: ['WAS', 'Wizards', 1961, 'Washington', 'Washington Wizards', 'District of Columbia', [1978]],
  1610612765: ['DET', 'Pistons', 1948, 'Detroit', 'Detroit Pistons', 'Michigan', [1989, 1990, 2004]],
  1610612766: ['CHA', 'Hornets', 1988, 'Charlotte', 'Charlotte Hornets', 'North Carolina', []],
};

export const TEAMS_ABBR = Object.entries(TEAMS).reduce((acc, cur) => {
  const [key, value] = cur;
  acc[value[0]] = key;
  return acc;
}, {});

export const BOXSCORE_COLS_ALL = [
  'SEASON_YEAR', 'PLAYER_ID', 'PLAYER_NAME', 'NICKNAME', 'TEAM_ID', 'TEAM_ABBREVIATION', 'TEAM_NAME', 'GAME_ID', 'GAME_DATE', 'MATCHUP', 'WL', 'MIN', 'FGM', 'FGA', 'FG_PCT', 'FG3M', 'FG3A', 'FG3_PCT', 'FTM', 'FTA', 'FT_PCT', 'OREB', 'DREB', 'REB', 'AST',
  'TOV', 'STL', 'BLK', 'BLKA', 'PF', 'PFD', 'PTS', 'PLUS_MINUS', 'NBA_FANTASY_PTS', 'DD2', 'TD3',
  'WNBA_FANTASY_PTS', 'GP_RANK', 'W_RANK', 'L_RANK', 'W_PCT_RANK', 'MIN_RANK', 'FGM_RANK', 'FGA_RANK', 'FG_PCT_RANK', 'FG3M_RANK', 'FG3A_RANK', 'FG3_PCT_RANK', 'FTM_RANK', 'FTA_RANK', 'FT_PCT_RANK',
  'OREB_RANK', 'DREB_RANK', 'REB_RANK', 'AST_RANK', 'TOV_RANK', 'STL_RANK', 'BLK_RANK', 'BLKA_RANK', 'PF_RANK', 'PFD_RANK', 'PTS_RANK', 'PLUS_MINUS_RANK', 'NBA_FANTASY_PTS_RANK', 'DD2_RANK', 'TD3_RANK', 'WNBA_FANTASY_PTS_RANK', 'VIDEO_AVAILABLE_FLAG',
];

export const BOXSCORE_COLS = [
  // 'SEASON_YEAR', 'PLAYER_ID',
  'PLAYER_NAME',
  // 'NICKNAME', 'TEAM_ID', 'TEAM_ABBREVIATION', 'TEAM_NAME',
  // 'GAME_ID',
  'GAME_DATE',
  'MATCHUP',
  'WL',
  'MIN',
  'PTS', 'REB', 'AST',
  'PLUS_MINUS',
  'FGM', 'FGA', 'FG_PCT', 'FG3M', 'FG3A', 'FG3_PCT', 'FTM', 'FTA', 'FT_PCT',
  'OREB', 'DREB',
  'TOV', 'STL', 'BLK', 'BLKA', 'PF', 'PFD',
  // 'NBA_FANTASY_PTS', 'DD2', 'TD3',
  // 'WNBA_FANTASY_PTS', 'GP_RANK', 'W_RANK', 'L_RANK', 'W_PCT_RANK', 'MIN_RANK', 'FGM_RANK',
  // 'FGA_RANK',
  // 'FG_PCT_RANK', 'FG3M_RANK', 'FG3A_RANK', 'FG3_PCT_RANK', 'FTM_RANK', 'FTA_RANK', 'FT_PCT_RANK',
  // 'OREB_RANK', 'DREB_RANK', 'REB_RANK', 'AST_RANK', 'TOV_RANK', 'STL_RANK',
  // 'BLK_RANK', 'BLKA_RANK',
  // 'PF_RANK', 'PFD_RANK', 'PTS_RANK', 'PLUS_MINUS_RANK', 'NBA_FANTASY_PTS_RANK',
  // 'DD2_RANK', 'TD3_RANK', 'WNBA_FANTASY_PTS_RANK', 'VIDEO_AVAILABLE_FLAG',
].map(c => ({ Header: c, accessor: c }));

export const REBOUNDING_COLS = [
  'TEAM_ID',
  'TEAM_ABBREVIATION',
  'TEAM_NAME',
  'GP',
  'W',
  'L',
  'MIN',
  // 'OREB',
  // 'OREB_CONTEST',
  // 'OREB_UNCONTEST',
  // 'OREB_CONTEST_PCT',
  // 'OREB_CHANCES',
  // 'OREB_CHANCE_PCT',
  // 'OREB_CHANCE_DEFER',
  // 'OREB_CHANCE_PCT_ADJ',
  // 'AVG_OREB_DIST',
  // 'DREB',
  // 'DREB_CONTEST',
  // 'DREB_UNCONTEST',
  // 'DREB_CONTEST_PCT',
  // 'DREB_CHANCES',
  // 'DREB_CHANCE_PCT',
  // 'DREB_CHANCE_DEFER',
  // 'DREB_CHANCE_PCT_ADJ',
  'AVG_DREB_DIST',
  'REB',
  'REB_CONTEST',
  'REB_UNCONTEST',
  'REB_CONTEST_PCT',
  'REB_CHANCES',
  'REB_CHANCE_PCT',
  'REB_CHANCE_DEFER',
  'REB_CHANCE_PCT_ADJ',
  'AVG_REB_DIST',
].map(c => ({ Header: c, accessor: c }));

export const playerGamelogsParams = (playerId, seasonType = 'Regular Season') => ({
  'MeasureType': 'Base',
  'PerMode': 'Totals',
  'LeagueID': '00',
  'Season': '2023-24',
  // 'SeasonType': 'Regular Season',
  'SeasonType': seasonType,
  'PORound': 0,
  // 'TeamID': teamId,
  'TeamID': null,
  'PlayerID': playerId,
  // 'PlayerID': null,
  'Outcome': null,
  'Location': null,
  'Month': 0,
  'SeasonSegment': null,
  'DateFrom': null,
  // 'DateFrom': '11/01/2022',
  'DateTo': null,
  'OppTeamID': 0,
  'VsConference': null,
  'VsDivision': null,
  'GameSegment': null,
  'Period': 0,
  'ShotClockRange': null,
  'LastNGames': 0,
  'cache': 0,
  // 'from': new Date(Date.now() - (2 * 86400 * 1000)).toISOString().split('T')[0],
  'from': new Date(Date.now()).toISOString().split('T')[0],
});

export const reboundingParams = () => ({
  'PtMeasureType': 'Rebounding',
  'PerMode': 'PerGame',
  'LeagueID': '00',
  'Season': '2023-24',
  'SeasonType': 'Regular Season',
  'PORound': 0,
  'Outcome': null,
  'Location': null,
  'Month': 0,
  'SeasonSegment': null,
  'DateFrom': null,
  'DateTo': null,
  'OpponentTeamID': 0,
  'VsConference': null,
  'VsDivision': null,
  'TeamID': 0,
  'Conference': null,
  'Division': null,
  'LastNGames': 0,
  'GameScope': null,
  'PlayerExperience': null,
  'PlayerPosition': null,
  'StarterBench': null,
  'DraftYear': null,
  'DraftPick': null,
  'College': null,
  'Country': null,
  'Height': null,
  'Weight': null,
  'PlayerOrTeam': 'Team',
  'cache': 0,
  // 'from': new Date(Date.now() - (2 * 86400 * 1000)).toISOString().split('T')[0],
  'from': new Date(Date.now()).toISOString().split('T')[0],
});

// https://stats.nba.com/stats/leaguedashptstats?College=&Conference=&Country=&DateFrom=&DateTo=&Division=&DraftPick=&DraftYear=&GameScope=&Height=&LastNGames=0&LeagueID=00&Location=&Month=0&OpponentTeamID=0&Outcome=&PORound=0&PerMode=PerGame&PlayerExperience=&PlayerOrTeam=Team&PlayerPosition=&PtMeasureType=Rebounding&Season=2022-23&SeasonSegment=&SeasonType=Regular%20Season&StarterBench=&TeamID=0&VsConference=&VsDivision=&Weight=

// {
// TEAM_ABBREVIATION: teamStat.TEAM_ABBREVIATION,
// REB: teamStat.REB,
// REB_RANK: teamStat.REB_RANK,
// REB_AVG: avgs.REB,
// REB_CHANCES: teamStat.REB_CHANCES,
// REB_CHANCES_RANK: teamStat.REB_CHANCES_RANK,
// REB_CHANCES_AVG: avgs.REB_CHANCES,
// REB_CHANCE_PCT: teamStat.REB_CHANCE_PCT,

// OREB: teamStat.OREB,
// OREB_RANK: teamStat.OREB_RANK,
// OREB_AVG: avgs.OREB,
// OREB_CHANCES: teamStat.OREB_CHANCES,
// OREB_CHANCES_RANK: teamStat.OREB_CHANCES_RANK,
// OREB_CHANCE_PCT: teamStat.OREB_CHANCE_PCT,

// DREB: teamStat.DREB,
// DREB_RANK: teamStat.DREB_RANK,
// DREB_AVG: avgs.DREB,
// DREB_CHANCES: teamStat.DREB_CHANCES,
// DREB_CHANCES_RANK: teamStat.DREB_CHANCES_RANK,
// DREB_CHANCE_PCT: teamStat.DREB_CHANCE_PCT,

// REB: teamStat.REB,
// REB_RANK: teamStat.REB_RANK,
// REB_AVG: avgs.REB,

// OREB: teamStat.OREB,
// OREB_RANK: teamStat.OREB_RANK,
// OREB_AVG: avgs.OREB,

// DREB: teamStat.DREB,
// DREB_RANK: teamStat.DREB_RANK,
// DREB_AVG: avgs.DREB,

// }
